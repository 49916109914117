/**
 * Component Navigation
 * @module Navigation
 * @param  {jQuery} $ Instance of jQuery
 * @return {Object} List of navigation methods
 */
XA.component.LoggedInNavigation = (function ($) {
  const api = {
    initialiazed: false,

    init() {
      $(document).on('show.bs.dropdown', (e) => {
        if (e.relatedTarget.id === 'LoggedInDropdownButton') {
          $('#LoggedInDropdownButton')
            .find('#chevron')
            .removeClass('fa-angle-down')
            .addClass('fa-angle-up');
        }
      })
      ;
      $(document).on('hide.bs.dropdown', (e) => {
        if (e.relatedTarget.id === 'LoggedInDropdownButton') {
          $('#LoggedInDropdownButton')
            .find('#chevron')
            .removeClass('fa-angle-up')
            .addClass('fa-angle-down');
        }
      });

    }
  };

  return api;
}) (jQuery, document);
XA.register('LoggedInNavigation', XA.component.LoggedInNavigation);
